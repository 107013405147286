@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-transition {
  transition: all 0.2s ease-in-out;
}

@layer base {
  html {
    font-family: 'Montserrat', sans-serif;
  }
}

.prose table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
}

.prose th,
.prose td {
  padding: 0.75rem;
  text-align: left;
  border: 1px solid;
}

.dark .prose th,
.dark .prose td {
  border-color: rgb(55, 65, 81);
}

.prose th,
.prose td {
  border-color: rgb(229, 231, 235);
}